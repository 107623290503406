<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" v-if="pageLoaded">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>جزئیات ویزیت</h3>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-title>
      <div class="mt-4">
        <v-tabs
          fixed-tabs
          background-color="transparent"
          v-model="tab"
          :vertical="deviceType == 'mobile' ? true : false"
        >
          <v-tab
            class="tab-title"
            :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
          >
            اطلاعات ویزیت</v-tab
          >
          <v-tab
            class="tab-title"
            :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
          >
            اطلاعات بیمار</v-tab
          >
          <v-tab
            class="tab-title"
            :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
            style="max-width: 100% !important"
          >
            تاریخچه ویزیت‌ها
          </v-tab>
        </v-tabs>
        <v-card class="br-card">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card class="px-4 py-8">
                <v-row>
                  <v-col md="6" sm="6" cols="12"
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">شماره ویزیت:</span>
                      {{ visitInfo.no }}</span
                    ></v-col
                  >
                  <v-col md="6" sm="6" cols="12"
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">زمان ویزیت:</span>
                      {{
                        " تاریخ " +
                        visitInfo.date.split("-")[0] +
                        " - ساعت " +
                        visitInfo.date.split("-")[1]
                      }}</span
                    ></v-col
                  >
                </v-row>
                <v-row>
                  <v-col md="6" sm="6" cols="12"
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text"
                        >نام {{ isDentist == "true" ? "دندانپزشک" : "پزشک" }}:
                      </span>
                      {{ visitInfo.doctorName }}</span
                    ></v-col
                  >
                  <v-col md="6" sm="6" cols="12" v-if="isDentist != 'true'"
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">تخصص پزشک:</span>
                      {{ visitInfo.expertise }}
                    </span></v-col
                  >
                  <v-col md="6" sm="6" cols="12" v-else
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">نام پذیرش:</span>
                      {{ visitInfo.receptionName }}</span
                    ></v-col
                  >
                </v-row>
                <v-row>
                  <v-col md="6" sm="6" cols="12" v-if="isDentist != 'true'"
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">نام پذیرش:</span>
                      {{ visitInfo.receptionName }}</span
                    ></v-col
                  >
                  <v-col md="6" sm="6" cols="12" v-else
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">نام پرستار:</span>
                      {{ visitInfo.nurseName }}</span
                    ></v-col
                  >

                  <v-col md="6" sm="6" cols="12" v-if="isDentist != 'true'"
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">نام پرستار:</span>
                      {{ visitInfo.nurseName }}</span
                    ></v-col
                  >
                  <v-col md="6" sm="6" cols="12" v-else
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">وضعیت:</span>
                      {{ visitInfo.done ? "انجام شده" : "انجام نشده" }}
                    </span></v-col
                  >
                </v-row>
                <v-row v-if="isDentist != 'true'">
                  <v-col md="6" sm="6" cols="12"
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">وضعیت:</span>
                      {{ visitInfo.done ? "انجام شده" : "انجام نشده" }}
                    </span></v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="12"
                    ><v-icon class="primary--text">mdi-circle-medium</v-icon
                    ><span class="h2"
                      ><span class="primary--text">خدمات ویزیت:</span>
                      <span
                        v-for="(item, index) in JSON.parse(visitInfo.services)"
                        :key="index"
                      >
                        {{ item }}
                        <span
                          v-if="
                            index != JSON.parse(visitInfo.services).length - 1
                          "
                        >
                          -
                        </span>
                      </span>
                    </span></v-col
                  >
                </v-row>
                <v-row
                  v-if="role == 'doctor' && visitInfo.visitType != 'nurse'"
                  class="mt-8"
                >
                  <v-col md="4" sm="12" cols="12">
                    <v-card class="box">
                      <v-card-title class="text-primary">
                        شرح حال بیمار
                      </v-card-title>
                      <v-card-text>
                        <textarea
                          v-model="visitInfo.description"
                          class="w-100"
                          rows="4"
                          style="line-height: 24px"
                          placeholder="لطفا شرح حال بیمار را اینجا بنویسید..."
                        ></textarea>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="4" sm="12" cols="12">
                    <v-card class="box">
                      <v-card-title class="text-primary">
                        تشخیص
                        {{ isDentist == "true" ? "دندانپزشک" : "پزشک" }}
                      </v-card-title>
                      <v-card-text>
                        <textarea
                          v-model="visitInfo.diagnosis"
                          class="inset w-100"
                          rows="4"
                          style="line-height: 24px"
                          placeholder="لطفا تشخیص خود را اینجا بنویسید..."
                        ></textarea>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="4" sm="12" cols="12">
                    <v-card class="box">
                      <v-card-title class="text-primary">
                        {{
                          visitInfo.visitType == "dentist"
                            ? "طرح درمان"
                            : "تجویز پزشک"
                        }}
                      </v-card-title>
                      <v-card-text>
                        <textarea
                          v-model="visitInfo.ePrescription"
                          class="inset w-100"
                          rows="4"
                          style="line-height: 24px"
                          placeholder="لطفا تجویز خود را اینجا بنویسید..."
                        ></textarea>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    role == 'nurse' ||
                    (role == 'doctor' && visitInfo.visitType == 'nurse')
                  "
                >
                  <v-col class="d-flex flex-column pt-0">
                    <v-checkbox
                      label="حساسیت دارویی دارد."
                      v-model="visitInfo.isMedicalAllergy"
                      hide-details
                    >
                    </v-checkbox>
                    <v-card
                      class="min-box mt-4"
                      v-if="visitInfo.isMedicalAllergy"
                    >
                      <v-card-title class="text-primary">
                        توضیحات حساسیت دارویی
                      </v-card-title>
                      <v-card-text class="pb-1">
                        <textarea
                          v-model="visitInfo.medicalAllergy"
                          class="inset w-100"
                          rows="1"
                          style="line-height: 24px"
                          hide-details
                          placeholder="لطفا توضیحات مربوط به حساسیت دارویی بیمار را اینجا بنویسید..."
                        ></textarea>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  class="mt-0"
                  v-if="
                    role == 'nurse' ||
                    (role == 'doctor' && visitInfo.visitType == 'nurse')
                  "
                >
                  <v-col class="d-flex flex-column pb-0">
                    <v-card class="min-box mt-4">
                      <v-card-title class="text-primary">
                        توضیحات بیماری
                      </v-card-title>
                      <v-card-text class="pb-1">
                        <textarea
                          v-model="visitInfo.disease"
                          class="inset w-100"
                          rows="1"
                          hide-details
                          style="line-height: 24px"
                          placeholder="لطفا توضیحات مربوط به بیماری را اینجا بنویسید..."
                        ></textarea>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row
                  v-if="
                    role == 'nurse' ||
                    (role == 'doctor' && visitInfo.visitType == 'nurse')
                  "
                  class="mt-6"
                >
                  <v-col>
                    <v-card class="box">
                      <v-card-title class="text-primary">
                        خدمات پرستاری ارائه شده
                      </v-card-title>
                      <v-card-text>
                        <textarea
                          style="line-height: 24px"
                          v-model="visitInfo.description"
                          class="inset w-100"
                          rows="4"
                          placeholder="لطفا خدمات مورد نظر خود را اینجا بنویسید..."
                        ></textarea>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card class="box">
                      <v-card-title class="text-primary">
                        یادداشت
                      </v-card-title>
                      <v-card-text>
                        <textarea
                          style="line-height: 24px"
                          v-model="visitInfo.note"
                          class="inset w-100"
                          rows="4"
                          placeholder="لطفا توضیحات مورد نظر خود را اینجا بنویسید..."
                        ></textarea>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    role == 'doctor' &&
                    (visitInfo.visitType == 'doctor' ||
                      visitInfo.visitType == 'dentist')
                  "
                >
                  <v-col>
                    <v-checkbox
                      v-model="visitInfo.needOtherDoctor"
                      color="primary"
                    >
                      <template v-slot:label>
                        <span class="mt-2">نیاز به ویزیت پزشک دیگر دارد</span>
                      </template>
                    </v-checkbox>
                    <div v-if="visitInfo.needOtherDoctor">
                      <v-select
                        v-if="isDentist == 'true'"
                        v-model="visitInfo.otherDoctorExpertise"
                        item-text="text"
                        item-value="text"
                        :items="expertises.filter((x) => x.text == 'دندانپزشک')"
                        label="تخصص پزشک"
                        outlined
                        dense
                        hide-details
                      ></v-select>
                      <v-select
                        v-else
                        v-model="visitInfo.otherDoctorExpertise"
                        item-text="text"
                        item-value="text"
                        :items="expertises.filter((x) => x.text != 'دندانپزشک')"
                        label="تخصص پزشک"
                        outlined
                        dense
                        hide-details
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>

                <div v-if="isDentist == 'true'">
                  <v-divider class="mb-6"></v-divider>
                  <v-row v-if="visitInfo.files.length" class="mb-3">
                    <v-col>
                      <span class="h2 primary--text font-weight-bold ms-2">
                        فایل‌های بارگذاری شده ({{
                          visitInfo.files.length
                        }}):</span
                      >
                      <!-- قسمت مربوط به فایل (لینک فایل گرفته شود) -->
                      <span
                        v-if="!visitInfo.files.length"
                        style="font-size: 15px"
                      >
                        --
                      </span>
                      <div class="d-flex flex-column mt-1 ps-5">
                        <ul class="ps-3">
                          <li
                            v-for="(item, index) in visitInfo.files"
                            :key="index"
                            class="pt-5"
                            style="font-size: 15px"
                          >
                            <a :href="item.url" target="blank">{{
                              item.name.length > 50
                                ? item.name.slice(0, 50) + "..."
                                : item.name
                            }}</a>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="ms-5 red-btn"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                    deleteDialog = true;
                                    deletedFileIndex = index;
                                  "
                                >
                                  <v-icon>mdi-file-remove</v-icon>
                                </v-btn>
                              </template>
                              <span class="white--text"> حذف فایل </span>
                            </v-tooltip>
                          </li>
                        </ul>
                      </div>
                    </v-col>
                    <v-divider class="mb-6"></v-divider>
                  </v-row>

                  <v-row>
                    <v-col>
                      <span class="h2 primary--text font-weight-bold ms-2"
                        >بارگذاری تصاویر درمانی:</span
                      >
                      <div
                        style="background-color: #f4f4f5; border-radius: 15px"
                        class="pa-8 mt-4"
                      >
                        <v-row>
                          <v-col>
                            <v-file-input
                              @change="fileUpload(file)"
                              v-model="file"
                              outlined
                              dense
                              prepend-inner-icon="upload_file"
                              prepend-icon=""
                              class="file-input payment-upload"
                              label="فایل مربوط به
                        درمان"
                            >
                            </v-file-input>
                            <v-progress-linear
                              rounded
                              v-if="myFile.fileShowProgress"
                              height="10"
                              :value="myFile.fileUploadPercentage"
                              class="mb-4 mt-0"
                            >
                            </v-progress-linear>
                            <v-row>
                              <div
                                v-if="myFile.fileIsUploaded"
                                color="green"
                                class="mt-3 mb-1 pa-3"
                                style="font-size: 16px"
                              >
                                فایل با موفقیت بارگذاری شد.
                              </div>
                            </v-row>
                            <div
                              v-if="uploadedFiles.length"
                              class="files-box d-flex flex-column"
                            >
                              <div class="files-title">
                                لیست فایل‌های مربوط به درمان ({{
                                  uploadedFiles.length
                                }})
                              </div>
                              <span
                                v-for="(item, index) in uploadedFiles"
                                :key="index"
                                class="mb-2 pt-2"
                              >
                                <span
                                  @click="deleteFile(index)"
                                  class="pointer"
                                >
                                  <v-icon class="red--text text--lighten-1 pe-2"
                                    >mdi-close-circle-outline</v-icon
                                  >
                                </span>
                                <a :href="item.url" target="blank">{{
                                  showName[index]
                                }}</a>
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                      </div></v-col
                    >
                  </v-row>
                </div>
                <div class="mt-15 text-left">
                  <v-btn
                    @click="finishVisit"
                    :disabled="computedBtn"
                    class="primary-btn me-4"
                    height="44"
                    :loading="doneBusy"
                  >
                    <v-icon class="me-1">mdi-pencil-outline</v-icon>
                    {{ visitInfo.done ? "ویرایش" : "اتمام ویزیت" }}
                  </v-btn>
                  <v-btn
                    class="secondary-btn"
                    height="44"
                    @click="goToPreviousPage()"
                  >
                    بازگشت
                    <v-icon class="ms-1">mdi-arrow-left-thick</v-icon></v-btn
                  >
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card class="px-4 py-8">
                <div
                  class="patient-div mb-6 pa-5"
                  style="min-height: 0 !important"
                >
                  <v-row>
                    <v-col md="6" sm="6" cols="12"
                      ><v-icon class="primary--text">mdi-circle-medium</v-icon
                      ><span class="h2"
                        ><span class="primary--text">نام بیمار:</span>
                        {{ patientInfo.name }}
                      </span></v-col
                    >
                    <v-col md="6" sm="6" cols="12"
                      ><v-icon class="primary--text">mdi-circle-medium</v-icon
                      ><span class="h2"
                        ><span class="primary--text">جنسیت:</span>
                        {{
                          patientInfo.gender ? patientInfo.gender : "-"
                        }}</span
                      ></v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col md="6" sm="6" cols="12"
                      ><v-icon class="primary--text">mdi-circle-medium</v-icon
                      ><span class="h2"
                        ><span class="primary--text">نوع بیمه:</span>
                        {{
                          patientInfo.insurType != "-"
                            ? insurTypes.filter(
                                (x) => x.id == patientInfo.insurType
                              )[0].text
                            : "-"
                        }}
                      </span></v-col
                    >
                    <v-col md="6" sm="6" cols="12"
                      ><v-icon class="primary--text">mdi-circle-medium</v-icon
                      ><span class="h2"
                        ><span class="primary--text">شماره بیمه:</span>
                        {{ patientInfo.insurNo }}</span
                      ></v-col
                    >
                  </v-row>
                </div>
                <v-row>
                  <v-col md="4" sm="12" cols="12">
                    <v-card class="patient-div">
                      <v-card-title class="text-primary">
                        بیماری‌ها
                      </v-card-title>
                      <v-card-text>
                        <div class="px-1">
                          <span class="h3" v-if="historyInfo.diseases">{{
                            historyInfo.diseases
                          }}</span>
                          <span v-else class="h4 grey--text"
                            >موردی ثبت نشده است.</span
                          >
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="4" sm="12" cols="12">
                    <v-card class="patient-div">
                      <v-card-title class="text-primary">
                        حساسیت‌ها
                      </v-card-title>
                      <v-card-text>
                        <div class="px-1">
                          <span class="h3" v-if="historyInfo.allergies">
                            {{ historyInfo.allergies }}
                          </span>
                          <span v-else class="h4 grey--text"
                            >موردی ثبت نشده است.</span
                          >
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="4" sm="12" cols="12">
                    <v-card class="patient-div">
                      <v-card-title class="text-primary">
                        داروهای در حال استفاده
                      </v-card-title>
                      <v-card-text>
                        <div class="px-1">
                          <span class="h3" v-if="historyInfo.drugs">
                            {{ historyInfo.drugs }}
                          </span>
                          <span v-else class="h4 grey--text"
                            >موردی ثبت نشده است.</span
                          >
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card class="px-6 py-8">
                <b-table
                  responsive
                  :fields="historyField"
                  :items="lastVisits"
                  class="mt-5"
                  empty-text="موردی برای نمایش وجود ندارد"
                  empty-filtered-text="موردی برای نمایش وجود ندارد"
                  show-empty
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div
                      class="text-center"
                      style="text-align: center; vertical-align: middle"
                    >
                      {{ data.value }}
                    </div>
                  </template>
                  <template v-slot:cell(services)="data">
                    <div
                      class="text-center"
                      style="text-align: center; vertical-align: middle"
                    >
                      <div>
                        <span
                          v-for="(item, index) in JSON.parse(data.value)"
                          :key="index"
                        >
                          {{ item }}
                          <span
                            v-if="index != JSON.parse(data.value).length - 1"
                          >
                            -
                          </span>
                        </span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(operation)="data">
                    <div style="text-align: center; vertical-align: middle">
                      <v-btn
                        @click="openDialog(data.item)"
                        class="primary-btn py-2 px-4"
                      >
                        مشاهده
                        {{ deviceType == "mobile" ? "" : "جزئیات" }}
                      </v-btn>
                    </div>
                  </template>
                  <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                </b-table>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </v-col>
    <v-dialog v-model="visitDetailDialog" width="600">
      <v-card class="pa-5" style="border-radius: 20px">
        <v-card-title> جزئیات ویزیت </v-card-title>
        <v-card-text class="mt-6 px-8">
          <v-row>
            <v-col class="text-start">
              <span class="primary--text font-weight-bold h2"> تاریخ: </span>
              <span class="h2">{{
                selectedVisit.date ? selectedVisit.date.split("-")[0] : ""
              }}</span>
            </v-col>
            <v-col class="text-start">
              <span class="primary--text font-weight-bold h2"> ساعت: </span>
              <span class="h2">{{
                selectedVisit.date ? selectedVisit.date.split("-")[1] : ""
              }}</span>
            </v-col>
          </v-row>
          <v-row v-if="selectedVisit.services">
            <v-col>
              <span class="primary--text font-weight-bold h2"> خدمات: </span>
              <span
                v-for="(item, index) in JSON.parse(selectedVisit.services)"
                :key="index"
                class="h2"
                style="line-height: 30px"
              >
                {{ item }}
                <span
                  v-if="index != JSON.parse(selectedVisit.services).length - 1"
                >
                  -
                </span>
              </span>
            </v-col>
          </v-row>
          <div
            v-if="
              selectedVisit.visitNoType == 'doctor' ||
              selectedVisit.visitNoType == 'dentist'
            "
            class="mt-3"
          >
            <v-row>
              <v-col>
                <span class="primary--text font-weight-bold h2">
                  شرح حال بیمار:
                </span>
                <p class="h3 text-justify" style="line-height: 30px">
                  {{
                    selectedVisit.description ? selectedVisit.description : "-"
                  }}
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <span class="primary--text font-weight-bold h2">
                  تشخیص
                  {{
                    selectedVisit.visitNoType == "dentist"
                      ? "دندانپزشک"
                      : "پزشک"
                  }}:
                </span>
                <p class="h3 text-justify" style="line-height: 30px">
                  {{ selectedVisit.diagnosis ? selectedVisit.diagnosis : "-" }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="primary--text font-weight-bold h2">
                  {{
                    selectedVisit.visitNoType == "dentist"
                      ? "طرح درمان:"
                      : "تجویز پزشک:"
                  }}
                </span>
                <p class="h3 text-justify" style="line-height: 30px">
                  {{ selectedVisit.diagnosis ? selectedVisit.diagnosis : "-" }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="role == 'doctor' && selectedVisit.needOtherDoctor">
              <v-col>
                <span class="font-weight-bold primary--text h3"
                  >بیمار نیاز به ویزیت مجدد توسط پزشک متخصص
                  {{ selectedVisit.otherDoctorExpertise }}
                  دارد.</span
                >
              </v-col>
            </v-row>
          </div>
          <div v-if="selectedVisit.visitNoType == 'nurse'" class="mt-3">
            <v-row>
              <v-col>
                <span class="primary--text font-weight-bold h2">
                  توضیحات بیماری:
                </span>
                <p class="h3 text-justify" style="line-height: 30px">
                  {{ selectedVisit.disease }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="primary--text font-weight-bold h2">
                  توضیحات حساسیت دارویی:
                </span>
                <p class="h2 text-justify" style="line-height: 30px">
                  {{
                    selectedVisit.isMedicalAllergy
                      ? selectedVisit.medicalAllergy
                      : "حساسیت دارویی ندارد"
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="primary--text font-weight-bold h2">
                  خدمات پرستاری ارائه شده :
                </span>
                <p class="h2 text-justify" style="line-height: 30px">
                  {{
                    selectedVisit.description ? selectedVisit.description : "-"
                  }}
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <div>
          <v-btn class="primary-btn" @click="visitDetailDialog = false"
            >بستن</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="600">
      <v-card class="py-3" style="border-radius: 20px">
        <v-card-title>
          <span class="h1">حذف فایل</span>
        </v-card-title>
        <v-card-text class="px-5 pb-0">
          <v-container>
            <v-row>
              <v-col>
                <h5>آیا از حذف این فایل اطمینان دارید؟</h5>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="ms-auto">
            <v-btn
              class="mb-2 me-2 text-light primary-btn"
              @click="deleteDialog = false"
            >
              خیر
            </v-btn>
            <v-btn
              class="mb-2 text-light red-btn"
              @click="removeFile(deletedFileIndex)"
              >بله
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: null,
      visitInfo: {},
      patientInfo: {},
      historyInfo: {},
      pageLoaded: false,
      visitDetailDialog: false,
      selectedVisit: {},
      role: "",
      historyField: [
        { key: "date", label: "تاریخ" },
        { key: "services", label: "خدمات" },
        { key: "operation", label: "عملیات" },
      ],
      fileUploadButDisable: false,
      variant: "info",
      striped: true,
      max: 100,
      file: [],
      fileIsUploaded: false,
      fileUploadPercentage: 0,
      fileShowProgress: false,
      myFile: new Object({
        fileUploadPercentage: 0,
      }),
      uploadedFiles: [],
      showName: [],
      isDentist: "",
      deletedFileIndex: "",
      deleteDialog: false,
      prevRoute: null,
      doneBusy: false,
    };
  },
  methods: {
    getVisitInfo() {
      this.vLoading = true;
      if (this.isDentist == "true") {
        this.uploadedFiles = [];
        this.myFile = new Object({
          fileUploadPercentage: 0,
        });
        this.showName = [];
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/medicalTeam/visit/getInfo",
            {
              visitId: this.$route.params.id,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.visitInfo = res.data.visit;
              this.patientInfo = res.data.patient;
              this.lastVisits = res.data.lastVisits;
              this.historyInfo = res.data.history;
              setTimeout(() => {
                this.pageLoaded = true;
              }, 200);
              this.vLoading = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.vLoading = false;
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.vLoading = false;
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/medicalTeam/visit/getInfo",
            {
              visitId: this.$route.params.id,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.vLoading = false;
            if (res.status == 200) {
              this.visitInfo = res.data.visit;
              this.patientInfo = res.data.patient;
              this.lastVisits = res.data.lastVisits;
              this.historyInfo = res.data.history;
              setTimeout(() => {
                this.pageLoaded = true;
              }, 200);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.vLoading = false;
          });
      }
    },
    openDialog(item) {
      this.selectedVisit = item;
      this.visitDetailDialog = true;
    },
    finishVisit() {
      this.doneBusy = true;
      if (this.isDentist == "true") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/medicalTeam/visit/done",
            {
              visitId: this.$route.params.id,
              description: this.visitInfo.description,
              diagnosis: this.visitInfo.diagnosis,
              ePrescription: this.visitInfo.ePrescription,
              disease: this.visitInfo.disease,
              isMedicalAllergy: this.visitInfo.isMedicalAllergy,
              medicalAllergy: this.visitInfo.isMedicalAllergy
                ? this.visitInfo.medicalAllergy
                : "",
              note: this.visitInfo.note,
              needOtherDoctor: this.visitInfo.needOtherDoctor,
              otherDoctorExpertise: this.visitInfo.needOtherDoctor
                ? this.visitInfo.otherDoctorExpertise
                : "",
              fileIds: this.visitInfo.fileIds,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.doneBusy = false;
            if (res.status == 200) {
              this.toast(res.data, "success");
              this.goToPreviousPage();
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.doneBusy = false;
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/medicalTeam/visit/done",
            {
              visitId: this.$route.params.id,
              description: this.visitInfo.description,
              diagnosis: this.visitInfo.diagnosis,
              ePrescription: this.visitInfo.ePrescription,
              disease: this.visitInfo.disease,
              isMedicalAllergy: this.visitInfo.isMedicalAllergy,
              medicalAllergy: this.visitInfo.isMedicalAllergy
                ? this.visitInfo.medicalAllergy
                : "",
              note: this.visitInfo.note,
              needOtherDoctor: this.visitInfo.needOtherDoctor,
              otherDoctorExpertise: this.visitInfo.needOtherDoctor
                ? this.visitInfo.otherDoctorExpertise
                : "",
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.doneBusy = false;
            if (res.status == 200) {
              this.toast(res.data, "success");
              this.goToPreviousPage();
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.doneBusy = false;
          });
      }
    },
    getDashboardInfo() {
      if (this.isDentist == "true") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/medicalTeam/dashboard/getInfo",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
              this.shiftDoctor = res.data.shiftDoctor;
              this.$emit("doctorDashboard", res.data.shiftDoctor);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.data, "error");
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/medicalTeam/dashbaord/getInfo",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
              this.shiftDoctor = res.data.shiftDoctor;
              this.$emit("doctorDashboard", res.data.shiftDoctor);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.data, "error");
          });
      }
    },
    goToPreviousPage() {
      !this.prevRoute || this.prevRoute.path == "/"
        ? this.$router.push("/" + this.role + "/visitsList")
        : this.$router.push(this.prevRoute.path);
    },
    fileUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.fileShowProgress = true;
      this.myFile.variant = "info";
      this.myFile.fileUploadPercentage = 0;
      this.myFile.fileIsUploaded = false;
      this.myFile.fileUploadButDisable = true;
      var formData = new FormData();

      formData.append(file.name, file);

      this.$http
        .post(
          this.baseUrl +
            "/clinic/dentistry/medicalTeam/visit/upload?visitId=" +
            this.$route.params.id,
          formData,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myFile.fileUploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          }
        )
        .then((d) => {
          if (d.status == 201) {
            this.showName.push(file.name);
            this.myFile.fileIsUploaded = true;
            this.myFile.fileUploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.fileUploadButDisable = false;
            this.uploadedFiles.push(d.data);
            this.visitInfo.fileIds.push(d.data.id);
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    deleteFile(index) {
      this.uploadedFiles.splice(index, 1);
      this.showName.splice(index, 1);
      this.visitInfo.fileIds.splice(index, 1);
    },
    removeFile(index) {
      this.visitInfo.fileIds.splice(index, 1);
      this.visitInfo.files.splice(index, 1);
      this.deleteDialog = false;
      this.deletedFileIndex = "";
    },
  },
  computed: {
    ...mapGetters(["insurTypes", "expertises"]),
    computedBtn() {
      if (this.role == "nurse") {
        if (!this.visitInfo.disease) {
          return true;
        }
        if (this.visitInfo.isMedicalAllergy && !this.visitInfo.medicalAllergy) {
          return true;
        }
        if (!this.visitInfo.note) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.isDentist = localStorage.getItem("isDentist");
    if (this.role == "doctor") {
      this.getDashboardInfo();
    }
    this.getVisitInfo();
  },
};
</script>
<style lang="scss">
.box {
  min-height: 150px;
  border: 1px solid #00a7b7 !important;
  border-radius: 10px !important;
  textarea {
    border-radius: 20px;
    outline: none;
    padding: 15px;
  }
  .v-card__title {
    font-size: 18px !important;
  }
}
.min-box {
  min-height: 80px !important;
  border: 1px solid #00a7b7 !important;
  border-radius: 10px !important;
  textarea {
    border-radius: 20px;
    outline: none;
    padding: 15px;
  }
  .v-card__title {
    font-size: 18px !important;
  }
}
.patient-div {
  background-color: #f4f4f5 !important;
  padding: 1.5rem;
  border-radius: 10px !important;
  min-height: 150px;
  .v-card__title {
    background-color: #f4f4f5 !important;
    font-size: 20px !important;
  }
  .v-text-field--outlined fieldset {
    border: none !important;
  }
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 80% !important;
}
.files-box {
  border: 1px solid rgb(172, 172, 172);
  text-align: right;
  font-size: 14px;
  margin-top: 35px;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  ul {
    li {
      text-decoration: none;
    }
  }
  .files-title {
    position: absolute;
    top: -9px;
    right: 20px;
    background-color: #f4f4f5;
    font-size: 14px;
    padding: 0 6px;
  }
}
.v-file-input .v-file-input__text.v-file-input__text--chips {
  display: none !important;
}
</style>
